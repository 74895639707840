




































































































import { stateModule, userModule } from '@/store'
import { Component, Vue }          from 'vue-property-decorator'
import { UserService }             from '@/services/user'
import { passwordRules, validPassword, passwordMatch, passwordRule } from '@/helpers/password'

import TextInput      from '@/components/inputs/Text.vue'
import CheckboxInput  from '@/components/inputs/Checkbox.vue'

import { AuthUserResource, NotificationSettings }      from '@/models/users/AuthUserResource'
import { ChangePasswordRequest } from '@/requests/auth/ChangePasswordRequest'

const userService = new UserService()

@Component({
  components: {
    TextInput,
    CheckboxInput
  }
})
export default class ProfilePage extends Vue {

  private passwordPattern: string = passwordRule


  private passwordRules = passwordRules

  private get validPassword() {
    return validPassword(this.passwordForm.password)
  }

  private get passwordMatch() {
    return passwordMatch(this.passwordForm.password, this.passwordForm.repeat_password)
  }

  private get user(): AuthUserResource | undefined {
    return userModule.user
  }

  private passwordForm: ChangePasswordRequest = new ChangePasswordRequest({
    current_password: '',
    password: '',
    repeat_password: ''
  })

  private passwordChangeSuccess: boolean = false

  private passwordFormErrors: ErrorResponse = {}

  private async changePassword(): Promise<void> {
    // Reset errors
    this.$set(this, 'passwordFormErrors', {})
    this.passwordChangeSuccess = false

    stateModule.setLoading(true)
    try {
      await userService.changePassword(this.passwordForm)
      this.passwordChangeSuccess = true
    } catch ({ message, errors }) {
      if (errors) {
        this.$set(this, 'passwordFormErrors', errors)
      }
    } finally {
      stateModule.setLoading(false)
    }
  }

  private resetErrors(key: string): void {
    this.$delete(this.passwordFormErrors, key)
  }

  private async updateNotificationSettings(setting: string, value: 'daily' | 'weekly' | 'none'): Promise<void> {
    stateModule.setLoading(true)
    const data: NotificationSettings = {}
    data[setting] = value
    try {
      await userService.updateNotificationSetting(data)
      await userModule.getMe()
    } finally {
      stateModule.setLoading(false)
    }
  }

}
