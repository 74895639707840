import { passwordRules, validPassword, passwordMatch } from '@/helpers/password'

export interface IChangePasswordRequest {
  current_password?: string
  password?: string
  repeat_password?: string
}

export class ChangePasswordRequest {

  public current_password: string
  public password: string
  public repeat_password: string

  constructor({ password = '', repeat_password = '', current_password = '' }: IChangePasswordRequest) {
    this.current_password = current_password
    this.password = password
    this.repeat_password = repeat_password
  }

  public get isValid() {
    return !!this.current_password && this.validPassword && this.matchingPasswords
  }

  public get validPassword() {
    return validPassword(this.password)
  }

  public get matchingPasswords() {
    return passwordMatch(this.password, this.repeat_password)
  }
}

